<template>
  <div>
    <canvas
      :id="chart_id"
      :width="width"
      :height="height"
      :aria-label="chart_id"
      role="img"
      :responsive="false"
      v-if="data.labels.length > 1"
    />
    <div v-if="data.labels.length <= 1" class="text-center bg-gray pb-4 pt-3">
      <img src="/assets/img/info.png" alt="info" height="200px" class="mt-3">
      <p class="mt-3">There is not enough data to display.</p>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  mounted() {
    this.draw();
  },

  data() {
    return {
      chart_id: `chart-id-${Math.random(10)}`,
    };
  },

  props: {
    type: {
      required: true,
    },
    data: {
      default() {
        return [];
      },
    },
    options: {
      default() {
        return [];
      },
    },
    width: {
      default() {
        return 300;
      },
    },
    height: {
      default() {
        return `150px`;
      },
    }
  },

  methods: {
    draw() {
      let ctx = document.getElementById(this.chart_id);

      if(this.type == 'line' || this.type == 'bar') {
        new Chart(ctx, {
          type: this.type,
          data: this.data,
          options: {
            scales: {
              y: {
                beginAtZero: true,
                max: null,
                min: null,
              },
            },
            plugins: {
              legend: {
                  display: true
              }
          }
          },
        });
      } else {
        new Chart(ctx, {
          type: this.type,
          data: this.data,
          options: {
            plugins: {
              legend: {
                  display: true
              }
          }
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.bg-gray {
  background: #f7f7f7;
}
</style>